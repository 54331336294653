import { Divider, Grid, Stack, styled, Button, InputAdornment, Typography } from '@mui/material';
import InputTextOutlinedV2 from 'components/FormElement/InputTextOutlinedV2';
import { candidateCvWorkExperienceInitialState } from 'pages/tas/candidateForm/candidateFields';
import { Fragment, useCallback, useMemo, useState } from 'react';
import ClearIcon from '@mui/icons-material/Clear';
import AddCommentIcon from '@mui/icons-material/AddComment';
import { useTheme } from '@emotion/react';
import _ from 'lodash';
import Joi from 'joi';
import { schemaWorkExperience } from 'validations/candidateRegister';
import DOMPurify from 'dompurify';
import StyledQuillInput from 'pages/common/StyledQuillInput';

const CustomBtn = styled(Button, {
  shouldForwardProp: (prop) => prop !== 'width',
})(({ width, theme }) => ({
  width: width ?? 'auto',
  paddingLeft: '0!important',
  paddingRight: '0!important',
  height: '36px',
  borderWidth: '2px',
  fontWeight: '700',
  fontSize: '16px',
  borderRadius: '3px',
  borderColor: '#0242D1',
  cursor: 'pointer',
  [theme.breakpoints.down('lg')]: {
    marginTop: '10px!important',
    width: '100%',
  },
}));

const RowResumeWorkExperience = ({
  fieldsWe,
  errorsWe,
  editing,
  index,
  crearDisabled,
  onClearWorkExperience,
  onHandleWorkExperience,
  resumeRequired,
}) => {
  const theme = useTheme();
  const [workExp, setWorkExp] = useState(candidateCvWorkExperienceInitialState);
  const [initialState, setInitialState] = useState('');
  const [errorsWex, setErrorsWex] = useState(candidateCvWorkExperienceInitialState);

  useMemo(() => {
    if (!_.isEqual(fieldsWe, initialState)) {
      setWorkExp(fieldsWe);
      setInitialState(fieldsWe);
    }
  }, [fieldsWe]);

  const handleInputTextSave = (e) => {
    const newFields = { ...workExp };
    newFields[e.target.name] = e.target.value;
    newFields.we_isValid = false;
    onHandleWorkExperience(index, newFields);
    validateWorkExperienceField(e.target);
    setWorkExp(newFields);
  };

  const validateWorkExperience = useCallback(() => {
    const schema = Joi.object(schemaWorkExperience);
    const { error } = schema.validate({ ...workExp }, { abortEarly: false });

    if (error && Object.keys(error?.details).length === 1 && error.details[0].context.key === 'we_isValid') {
      const newFields = { ...workExp };
      setErrorsWex({});
      newFields.we_isValid = true;
      setWorkExp(newFields);
      onHandleWorkExperience(index, newFields);
    } else {
      let errorsWe = {};
      for (let item of error.details) errorsWe[item.context.label] = { response: true, txt: item.message };
      if (errorsWe.we_description) delete errorsWe.we_isValid;
      // @ts-ignore
      setErrorsWex(errorsWe);
    }
  }, [index, onHandleWorkExperience, workExp]);

  const validateWorkExperienceField = useCallback(
    (field) => {
      const sliceSchema = Joi.object({ [field.name]: schemaWorkExperience[field.name] });
      const obj = { [field.name]: field.value };
      const { error } = sliceSchema.validate(obj, { abortEarly: false });
      let err = { ...errorsWex };
      if (error) err[field.name] = { response: true, txt: error?.details?.[0].message };
      else _.unset(err, field.name);
      setErrorsWex(err);
    },
    [errorsWex]
  );

  const handleErrorLastWe = useCallback(() => {
    if (errorsWex?.we_description) return errorsWex?.we_description?.txt;
    else if (errorsWex?.we_isValid) return 'Work experience must be validated for user';
    else return '';
  }, [errorsWex]);

  useMemo(() => {
    setErrorsWex(errorsWe);
  }, [errorsWe]);

  const handleStateButtonWe = useMemo(() => {
    if (resumeRequired && workExp.we_isValid) return true;
    return editing;
  }, [editing, resumeRequired, workExp.we_isValid]);

  const cleanHTML = (dirtyHTML) =>
    DOMPurify.sanitize(dirtyHTML, {
      USE_PROFILES: { html: true },
    });

  return (
    <Fragment>
      {index >= 1 && <Divider sx={{ mt: '32px', mb: '16px' }} variant="middle" />}
      {/* startDate */}
      <Grid container spacing={2} my={0.5}>
        <Grid item xs={12} md={3}>
          <InputTextOutlinedV2
            id={`we_startDate`}
            size={'normal'}
            type="text"
            label="START"
            error={errorsWex?.we_startDate?.txt}
            initialValue={workExp?.we_startDate || ''}
            onChangeInput={(e) => {
              handleInputTextSave(e);
            }}
            isDisabled={editing}
          />
        </Grid>
        {/* endDate */}
        <Grid item xs={12} md={3}>
          <InputTextOutlinedV2
            id={`we_endDate`}
            size={'normal'}
            type="text"
            label="END"
            error={errorsWex?.we_endDate?.txt}
            initialValue={workExp?.we_endDate || ''}
            onChangeInput={(e) => {
              handleInputTextSave(e);
            }}
            isDisabled={editing}
            endAdornment={
              <InputAdornment position="start">
                <AddCommentIcon
                  fontSize="small"
                  color="primary"
                  style={{
                    cursor: !editing ? 'pointer' : 'none',
                    color: !editing ? theme.palette.primary.main : 'rgba(0, 0, 0, 0.24)',
                  }}
                  onClick={() => (!editing ? handleInputTextSave({ target: { name: 'we_endDate', value: 'Current' } }) : {})}
                />
              </InputAdornment>
            }
          />
        </Grid>
      </Grid>
      {/* position */}
      <Grid container spacing={2} mt={1.5}>
        <Grid item xs={12} md={6}>
          <InputTextOutlinedV2
            id={`we_position`}
            size={'normal'}
            type="text"
            label="Position"
            error={errorsWex?.we_position?.txt}
            initialValue={workExp?.we_position || ''}
            onChangeInput={(e) => {
              handleInputTextSave(e);
            }}
            isDisabled={editing}
          />
        </Grid>
        {/* company */}
        <Grid item xs={12} md={3}>
          <InputTextOutlinedV2
            id={`we_company`}
            size={'normal'}
            type="text"
            label="Company"
            error={errorsWex?.we_company?.txt}
            initialValue={workExp?.we_company || ''}
            onChangeInput={(e) => {
              handleInputTextSave(e);
            }}
            isDisabled={editing}
          />
        </Grid>
        {/* location */}
        <Grid item xs={12} md={3}>
          <InputTextOutlinedV2
            id={`we_location`}
            size={'normal'}
            type="text"
            label="LOCATION"
            error={errorsWex?.we_location?.txt}
            initialValue={workExp?.we_location || ''}
            onChangeInput={(e) => {
              handleInputTextSave(e);
            }}
            isDisabled={editing}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2} mt={0.5}>
        {/* Description */}
        <Grid item xs={12}>
          <StyledQuillInput
            onChange={(val) =>
              handleInputTextSave({
                target: {
                  value: val,
                  name: 'we_description',
                },
              })
            }
            isDisabled={editing}
            currentValue={cleanHTML(_.unescape(_.unescape(workExp?.we_description || '')))}
          />
          {handleErrorLastWe() ? <Typography style={{ color: '#d32f2f' }}>{handleErrorLastWe()}</Typography> : null}
        </Grid>
        <Grid item xs={12}>
          <Stack direction="row" gap="12px" height="100%" justifyContent="flex-end" alignItems={'flex-end'} flexWrap="wrap">
            <CustomBtn
              sx={{ px: '16px!important' }}
              startIcon={<ClearIcon />}
              variant="contained"
              color="error"
              onClick={() => onClearWorkExperience(index)}
              disabled={crearDisabled}
            >
              Delete experience
            </CustomBtn>
            <CustomBtn
              onClick={() => validateWorkExperience()}
              variant="outlined"
              sx={{ minWidth: '300px', px: 0 }}
              disabled={handleStateButtonWe}
            >
              VALIDATE WORK EXPERIENCE
            </CustomBtn>
          </Stack>
        </Grid>
      </Grid>
    </Fragment>
  );
};

export default RowResumeWorkExperience;
