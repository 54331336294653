import { HOLIDAYS } from 'constants/project';
import { setValidationMessages } from 'utils/validationMessages';

const Joi = require('joi');

export const optionalRequiredString = Joi.when('intake_status', {
  is: Joi.string().valid('INTAKE'),
  then: Joi.string()
    .required()
    .messages(setValidationMessages(['required', 'string'])),
  otherwise: Joi.string().optional().allow(''),
});

export const schemaIntakeForm = Joi.object({
  ClientId: Joi.number()
    .required()
    .messages(setValidationMessages(['number', 'required'])),
  intake_position_title: Joi.string()
    .required()
    .messages(setValidationMessages(['string', 'required'])),
  intake_seniority: Joi.string()
    .required()
    .messages(setValidationMessages(['string', 'required'])),
  intake_headcount: Joi.number()
    .required()
    .messages(setValidationMessages(['required', 'number'])),
  intake_maxrate_usd: Joi.when('intake_status', {
    is: Joi.string().valid('INTAKE'),
    then: Joi.number()
      .required()
      .messages(setValidationMessages(['required', 'number'])),
    otherwise: Joi.string().optional().allow(''),
  }),
  intake_description: optionalRequiredString,
  intake_timezone: optionalRequiredString,
  intake_holidays: Joi.when('intake_status', {
    is: Joi.string().valid('INTAKE'),
    then: Joi.string()
      .required()
      .valid(HOLIDAYS.MX, HOLIDAYS.US)
      .messages(setValidationMessages(['any'])),
    otherwise: Joi.string().optional().allow(''),
  }),
  intake_working_hours_open: optionalRequiredString,
  intake_working_hours_close: optionalRequiredString,
  intake_interview_process: optionalRequiredString,
  intake_job_description: optionalRequiredString,
  industry_experienceCV: Joi.array().optional(),
  intake_codetest_link: Joi.string().uri().optional().allow(''),
  intake_codetest_file: Joi.string().optional().allow(''),
  intake_hardware_needs: Joi.when('intake_status', {
    is: Joi.string().valid('INTAKE'),
    then: Joi.number()
      .integer()
      .positive()
      .messages(setValidationMessages(['number'])),
    otherwise: Joi.number().integer().positive().allow(''),
  }),
  intake_status: Joi.string().valid('INTAKE', 'DRAFT').required(),
  intake_skills: Joi.when('intake_status', {
    is: Joi.string().valid('INTAKE'),
    then: Joi.array()
      .ordered(
        Joi.object().keys({
          SkillId: Joi.number()
            .integer()
            .required()
            .messages(setValidationMessages(['number', 'required'])),
          yearExperience: Joi.number()
            .integer()
            .required()
            .messages(setValidationMessages(['number', 'required'])),
          type: Joi.string()
            .required()
            .messages(setValidationMessages(['string', 'required'])),
        })
      )
      .items(
        Joi.object().keys({
          SkillId: Joi.number()
            .integer()
            .required()
            .allow('')
            .messages(setValidationMessages(['number', 'required'])),

          yearExperience: Joi.when('SkillId', {
            is: Joi.number().integer(),
            then: Joi.number()
              .integer()
              .required()
              .messages(setValidationMessages(['number', 'required'])),
            otherwise: Joi.number().integer().optional().allow(''),
          }),
          type: Joi.when('SkillId', {
            is: Joi.number().integer(),
            then: Joi.string()
              .required()
              .messages(setValidationMessages(['string', 'required'])),
            otherwise: Joi.string().optional().allow(''),
          }),
        })
      ),
    otherwise: Joi.array().items(
      Joi.object().keys({
        SkillId: Joi.number()
          .integer()
          .allow('')
          .messages(setValidationMessages(['number', 'required'])),
        yearExperience: Joi.when('SkillId', {
          is: Joi.number().integer(),
          then: Joi.number()
            .integer()
            .required()
            .messages(setValidationMessages(['number', 'required'])),
          otherwise: Joi.number().integer().optional().allow(''),
        }),
        type: Joi.when('SkillId', {
          is: Joi.number().integer(),
          then: Joi.string()
            .required()
            .messages(setValidationMessages(['string', 'required'])),
          otherwise: Joi.string().optional().allow(''),
        }),
      })
    ),
  }),
});
