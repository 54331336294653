import {
  Autocomplete,
  Button,
  Checkbox,
  FormControl,
  FormLabel,
  FormControlLabel,
  Grid,
  Stack,
  TextField,
  Typography,
  InputLabel,
  OutlinedInput,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from '@mui/material';
import { publish } from 'events.js';
import { useEffect, useMemo, useState } from 'react';
import { usePrevious, capitalizeString } from 'utils/utils';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import _ from 'lodash';
import { styled } from '@mui/system';
import { useGetSkillsQuery, useGetTagsQuery, useGetManagedCountriesQuery, useGetIndustriesExperienceQuery } from 'api/api';
import { seniority as seniorityOptions, timezoneOptsCand } from 'utils/staticData';
import { POSITIONS } from '@nexxus/constants';
import { CANDIDATEPROJECT_STATUS } from 'constants/candidate_project';
import InputAutoCompleteV2 from 'components/FormElement/InputAutoCompleteV2';
import { languages } from '../constants';

const gridItemStyles = {
  xs: 12,
  sm: 6,
  md: 3
};

const centerGridItemStyles = {
  xs: 12,
  sm: 6,
  md: 6
};

const IncludeLabel = styled(FormLabel)({
  marginBottom: 0,
  marginRight: '0.74rem',
});

const initialFilterState = {
  position: '',
  seniority: '',
  certifications: '',
  skills: [],
  tags: [],
  location: [],
  lenguages: [],
  LocationCountryId: [],
  timezone: [],
  show_blacklisted: false,
  show_archived: false,
  pipeline_status: '',
  name: '',
  email: '',
  industry_experinece: [],
  secondary_skills: []
};

const CandidateTableFilters = ({ showIncludeBox }) => {
  const { data: skills, isSuccess: skillsIsSuccess } = useGetSkillsQuery();
  const [skillValue, setSkillValue] = useState([]);
  const [secondarySkillValue, setSecondarySkillValue] = useState([]);
  const { data: tags, isSuccess: tagsIsSuccess } = useGetTagsQuery();
  const [tagValue, setTagValue] = useState([]);
  const [resetFilter, setResetFilter] = useState(false);
  const [filters, setFilters] = useState(initialFilterState);
  const [disableBtnFilter, setDisableBtnFilter] = useState(true);
  const previousFilters = usePrevious(filters);
  const { data: managedCountries } = useGetManagedCountriesQuery();
  const [locationOptions, setLocationOptions] = useState([]);
  const { data: indutryExperineces = [] } = useGetIndustriesExperienceQuery();
  
  useEffect(() => {
    if (managedCountries) {
      setLocationOptions(managedCountries.map((country) => ({ _id: country.id, label: country.name })));
    }
  }, [managedCountries]);

  useMemo(() => {
    const isFieldEmpty = (value) => {
      return value === '' || value.length === 0;
    };
    let disabled = true;
    Object.keys(filters).forEach((key) => {
      const filterValue = filters[key];
      if (!isFieldEmpty(filterValue)) {
        disabled = false;
      }
    });
    setDisableBtnFilter(disabled);
  }, [filters]);

  useMemo(() => {
    if (resetFilter && previousFilters !== filters) {
      publish('CandidateSearchFilterUpdate', filters);
    }
    setResetFilter(false);
  }, [resetFilter]);

  const FilterButton = styled(Button)({
    fontSize: 18,
    fontWeight: 'bold',
  });
  const showIncludeInputBox = showIncludeBox === undefined ? true : showIncludeBox;

  const filterSearch = () => {
    setFilters(initialFilterState);
    setSkillValue([]);
    setTagValue([]);
    setResetFilter(true);
  };

  const skillOptions = useMemo(() => {
    return skills?.filter((sk) => sk.isActive) || [];
  }, [skills]);

  return (
    <>
      <Grid container spacing={3} mb={3}>
        <Grid item xs={12} sm={6} md={3}>
          <InputAutoCompleteV2
            id="position"
            label="Position"
            placeholder="No Selection"
            opts={Object.values(POSITIONS)}
            onChangeInput={(e) => setFilters((prev) => ({ ...prev, position: e.target.value }))}
            initialValue={filters.position}
            defaultOption={true}
          />
        </Grid>
        <Grid item xs={6}>
          <FormControl fullWidth>
            <FormLabel htmlFor="skills">Skills</FormLabel>
            {skillsIsSuccess && (
              <Autocomplete
                multiple
                id="skills"
                limitTags={5}
                options={skillOptions}
                value={skillValue}
                getOptionLabel={(option) => option.name}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                renderInput={(params) => {
                  return <TextField {...params} placeholder={skillValue.length ? '' : 'Type to search Skills'} />;
                }}
                onChange={(ev, value) => {
                  setSkillValue(value);
                  setFilters((prev) => ({ ...prev, skills: value.map((skill) => skill.id) }));
                }}
              />
            )}
          </FormControl>
        </Grid>
        <Grid item {...gridItemStyles}>
          <FormControl sx={{ width: '100%' }}>
            <FormLabel>&nbsp;</FormLabel>
            <FilterButton
              variant="contained"
              color="primary"
              size="large"
              onClick={() => {
                if (previousFilters !== filters) {
                  publish('CandidateSearchFilterUpdate', filters);
                }
              }}
            >
              SEARCH
            </FilterButton>
          </FormControl>
        </Grid>
        <Accordion sx={{ position: 'inherit', paddingLeft: '12px' }}>
          <AccordionSummary
            aria-controls="panel1-content"
            id="panel1-header"
            expandIcon={<ExpandMoreIcon sx={{ color: '#014DD3' }} />}
          >
            <Typography sx={{ color: '#004dd3', fontSize: '12px', }} fontWeight='bold'>ADVANCE SEARCH</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Grid container spacing={3} mb={3}>
              <Grid item {...gridItemStyles}>
                <InputLabel htmlFor="name">Name</InputLabel>
                <OutlinedInput
                  id="name"
                  onChange={(e) => setFilters((prev) => ({ ...prev, name: e.target.value }))}
                  value={filters.name}
                  placeholder="Filter by name"
                  fullWidth
                />
              </Grid>
              <Grid item {...centerGridItemStyles}>
                <FormControl fullWidth>
                  <FormLabel htmlFor="secondary">Secondary Skills</FormLabel>
                  <Autocomplete
                    multiple
                    id="secondary_skills"
                    limitTags={5}
                    options={skillOptions}
                    value={secondarySkillValue}
                    getOptionLabel={(option) => option.name}
                    isOptionEqualToValue={(option, value) => option.id === value.id}
                    renderInput={(params) => {
                      return <TextField {...params} placeholder={skillValue.length ? '' : 'Type to search Skills'} />;
                    }}
                    onChange={(ev, value) => {
                      setSecondarySkillValue(value);
                      setFilters((prev) => ({ ...prev, secondary_skills: value.map((skill) => skill.id) }));
                    }}
                  />
                </FormControl>
              </Grid>
              <Grid item {...gridItemStyles}>
                <InputAutoCompleteV2
                  label="Seniority"
                  id="seniority"
                  placeholder="No Selection"
                  opts={seniorityOptions}
                  onChangeInput={(e) => setFilters((prev) => ({ ...prev, seniority: e.target.value }))}
                  initialValue={filters.seniority}
                  defaultOption={true}
                />
              </Grid>
              <Grid item {...gridItemStyles}>
                <InputAutoCompleteV2
                  id="ProjectStatus"
                  label="Project Stage"
                  size={'normal'}
                  opts={Object.keys(CANDIDATEPROJECT_STATUS).map((key, value) => {
                    return { _id: key.toLowerCase(), label: key.replaceAll('_', ' ') };
                  })}
                  initialValue={filters.pipeline_status}
                  placeholder={'No Selection'}
                  onChangeInput={(e) => setFilters((prev) => ({ ...prev, pipeline_status: e.target.value }))}
                  defaultOption={true}
                />
              </Grid>
              <Grid item {...centerGridItemStyles}>
                <FormControl fullWidth>
                  <FormLabel htmlFor="tags">TAGS</FormLabel>
                  {tagsIsSuccess && (
                    <Autocomplete
                      multiple
                      id="tags"
                      limitTags={5}
                      options={tags}
                      value={tagValue}
                      getOptionLabel={(option) => capitalizeString(option.name)}
                      isOptionEqualToValue={(option, value) => option.id === value.id}
                      renderInput={(params) => {
                        return <TextField {...params} placeholder={tagValue.length ? '' : 'Type to search Tags'} />;
                      }}
                      onChange={(ev, value) => {
                        setTagValue(value);
                        setFilters((prev) => ({ ...prev, tags: value.map((tag) => tag.id) }));
                      }}
                      getOptionDisabled={(option) => (tagValue.length >= 3 || tagValue.includes(option) ? true : false)}
                    />
                  )}
                </FormControl>
              </Grid>
              <Grid item {...gridItemStyles}>
                <InputAutoCompleteV2
                  label="Industry Experience"
                  id="industry_experience"
                  placeholder="Industry Experience"
                  opts={indutryExperineces?.map((i) => ({ _id: i.id, label: i.name }))}
                  onChangeInput={(e) => setFilters((prev) => ({ ...prev, industry_experinece: e.target.value }))}
                  initialValue={filters.industry_experinece}
                  defaultOption={true}
                />
              </Grid>
              <Grid item {...gridItemStyles}>
                <InputLabel htmlFor="certifications">Certifications</InputLabel>
                <OutlinedInput
                  id="certifications"
                  onChange={(e) => setFilters((prev) => ({ ...prev, certifications: e.target.value }))}
                  value={filters.certifications}
                  placeholder="Filter by certification"
                  fullWidth
                />
              </Grid>
              <Grid item {...gridItemStyles}>
                <InputAutoCompleteV2
                  label="Location"
                  id="LocationCountryId"
                  placeholder="No Selection"
                  opts={locationOptions}
                  onChangeInput={(e) => {
                    setFilters((prev) => ({ ...prev, LocationCountryId: e.target.value }));
                  }}
                  initialValue={filters.LocationCountryId}
                  multiple={true}
                  defaultOption={false}
                  limitResults={true}
                />
              </Grid>
              <Grid item {...gridItemStyles}>
                <InputAutoCompleteV2
                  label="Timezone"
                  id="timezone"
                  placeholder="No Selection"
                  opts={_.orderBy(timezoneOptsCand, [(timezone) => timezone._id.toLowerCase()], ['asc'])}
                  onChangeInput={(e) => {
                    setFilters((prev) => ({ ...prev, timezone: e.target.value }));
                  }}
                  initialValue={filters.timezone}
                  multiple={true}
                  defaultOption={false}
                  limitResults={true}
                />
              </Grid>
              <Grid item {...gridItemStyles}>
                <InputAutoCompleteV2
                  label="LANGUAGES"
                  id="languages"
                  placeholder="No Selection"
                  opts={languages}
                  onChangeInput={(e) => {
                    setFilters((prev) => ({ ...prev, lenguages: e.target.value }));
                  }}
                  initialValue={filters.lenguages}
                  multiple={true}
                  defaultOption={false}
                  limitResults={true}
                />
              </Grid>
              <Grid item {...gridItemStyles}>
                <InputLabel htmlFor="email">Email</InputLabel>
                <OutlinedInput
                  id="email"
                  onChange={(e) => setFilters((prev) => ({ ...prev, email: e.target.value }))}
                  value={filters.email}
                  placeholder="Filter by email"
                  fullWidth
                />
              </Grid>

              <Grid item {...gridItemStyles} md={6}>
              {showIncludeInputBox && (
                <>
                  <FormControl>
                    <IncludeLabel>INCLUDE:</IncludeLabel>
                  </FormControl>
                  <Stack direction="row" spacing={2} justifyContent="flex-start" alignItems="center" mb={2}>
                    <FormControl>
                      <FormControlLabel
                        control={
                          <Checkbox
                            size="medium"
                            checked={filters.show_blacklisted}
                            onChange={(_e, checked) => setFilters((prev) => ({ ...prev, show_blacklisted: checked }))}
                          />
                        }
                        label={<Typography variant="caption">BLACKLISTED</Typography>}
                      />
                    </FormControl>
                    <FormControl>
                      <FormControlLabel
                        control={
                          <Checkbox
                            size="medium"
                            checked={filters.show_archived}
                            onChange={(_e, checked) => setFilters((prev) => ({ ...prev, show_archived: checked }))}
                          />
                        }
                        label={<Typography variant="caption">ARCHIVED</Typography>}
                      />
                    </FormControl>
                  </Stack>
                </>
              )}  
              </Grid>
              <Grid item {...gridItemStyles}>
                <Stack direction="row" justifyContent="flex-end" alignItems="center" mb={2}>
                  <Button
                    variant="contained"
                    color={'ternary'}
                    disabled={disableBtnFilter}
                    sx={{ width: '100%', padding: 2, border: 'solid #014DD3' }}
                    onClick={filterSearch}
                  >
                    Clear Filters
                  </Button>
                </Stack>
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>
      </Grid>
    </>
  );
};

export default CandidateTableFilters;
