import { useEffect, useState } from 'react';

import { Box } from '@mui/material';
import MainLayout from 'components/MainLayout/MainLayout';
import { CANDIDATE_STATUS } from 'constants/candidate';
import { ROLE_SUPERTAS, ROLE_TAS } from 'constants/users';
import useBreadcrumbs from 'hooks/useBreadcrumbs';
import CandidateFormV2 from 'pages/tas/candidateForm/CandidateFormV2';
import CandidateTabs from 'pages/tas/candidateTabs/CandidateTabs';
import { connect } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import candidateService from 'services/candidateService';
import { loginExpire } from 'store/auth';

import usePreventLocation from 'hooks/usePreventLocation';
import { useGetSkillsResumeQuery } from 'api/api';

const NewEditCandidate = (props) => {
  const { CandidateId } = useParams();
  let navigate = useNavigate();
  const [editing, setEditing] = useState(props.editing);
  const [candidateDB, setCandidateDB] = useState({});
  const [initialSkills, setInitialSkills] = useState([]);
  const [pageId, setPageId] = useState({});
  const [isCandidateActive, setIsCandidateActive] = useState(true);
  const { setBreadcrumbs } = useBreadcrumbs();
  const { data: skillsOptions } = useGetSkillsResumeQuery();

  useEffect(() => {
    if (!!CandidateId && !editing) {
      setPageId({ name: 'Edit Candidate', id: `edit-${CandidateId}` });
    }
    if (!!CandidateId && !!editing) {
      setPageId({ name: 'View Candidate', id: `view-${CandidateId}` });
    }
    if (!CandidateId) {
      setPageId({ name: 'Create Candidate', id: 'create-new' });
    }
  }, [CandidateId, editing]);

  useEffect(() => {
    (() => {
      if (!!CandidateId && CandidateId !== 'create') {
        candidateService.getCandidateDetails(CandidateId).then(({ data = {}}) => {
          let defaultSkills = [];
          if (data?.Skills?.length === 0) {
            [...new Array(5)].forEach(() => {
              defaultSkills.push({ id: '', name: '' });
            });
          } else {
            defaultSkills = [...(data?.Skills || [])];
          }
          setIsCandidateActive(!data.blacklisted);
          setCandidateDB(data);
          setInitialSkills(defaultSkills);
        })
      } else {
        if (![ROLE_SUPERTAS, ROLE_TAS].includes(props.auth.user.role) === true) navigate('/403');
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [CandidateId]);

  useEffect(() => {
    setBreadcrumbs([
      {
        name: 'Dashboard',
        url: '/tas/dashboard',
      },
      {
        name: 'Candidates',
        url: '/tas/candidates/search',
      },
      {
        // @ts-ignore
        name: pageId.name,
      },
    ]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setBreadcrumbs, editing, CandidateId, pageId]);

  const { setHasChanged, hasChanged } = usePreventLocation();

  return (
    <MainLayout {...props} current="candidates">
      <Box>
        <CandidateFormV2
          editing={editing}
          setEditing={setEditing}
          setIsCandidateActive={setIsCandidateActive}
          setHasChanged={setHasChanged}
          skillsOptions={skillsOptions?.map(({ id, name }) => ({ _id: id, label: name })) || []}
        />
        {typeof CandidateId !== 'undefined' && (
          <CandidateTabs
            isDisabled={editing}
            skills={initialSkills}
            candidateId={CandidateId}
            // @ts-ignore
            activeCandidate={isCandidateActive && candidateDB?.status === CANDIDATE_STATUS.ACTIVE}
            setHasChanged={setHasChanged}
            hasChanged={hasChanged}
          />
        )}
      </Box>
    </MainLayout>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

const mapDispatchToProps = (dispatch) => {
  return {
    loginExpire: (user) => {
      dispatch(loginExpire(user));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(NewEditCandidate);
