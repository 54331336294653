import http from './httpService';

function getClient(ClientId) {
  return http.get(`${process.env.REACT_APP_API_BACKEND}/v1/clients/${ClientId}`);
}

function createClient(body) {
  return http.post(`${process.env.REACT_APP_API_BACKEND}/v1/clients`, body);
}

function updateClient(ClientId, body) {
  return http.patch(`${process.env.REACT_APP_API_BACKEND}/v1/clients/${ClientId}`, body);
}

function registerIntakePosition(body) {
  return http.post(process.env.REACT_APP_API_BACKEND + '/v1/clients/intake', body);
}

function updateIntakePosition(body, ProjectId) {
  return http.patch(`${process.env.REACT_APP_API_BACKEND}/v1/clients/intake/${ProjectId}`, body);
}

function getClientProjects(ClientId, filters) {
  return http.get(`${process.env.REACT_APP_API_BACKEND}/v1/projects/client/${ClientId}`, {
    params: filters,
  });
}

const ClientService = {
  getClient,
  createClient,
  updateClient,
  getClientProjects,
  registerIntakePosition,
  updateIntakePosition,
};

export default ClientService;
