import { connect } from 'react-redux';

import { Box, Stack, Typography } from '@mui/material';

import { useCallback, useEffect, useState } from 'react';
import { loginExpire } from 'store/auth';
import MainLayout from './../../components/MainLayoutRedesign/MainLayout';
import useBreadcrumbs from '../../hooks/useBreadcrumbs';
import MainLayoutV1 from 'components/MainLayout/MainLayout';

import { useGetCurrentUserQuery, useGetProjectsQuery } from 'api/api';
import { DataGrid, GridActionsCellItem } from '@mui/x-data-grid';
import { capitalizeString } from 'utils/utils';
import moment from 'moment';

import { SkillsPopover, TooltipHelper } from '@nexxus/components';
import { FiberManualRecord, EditNoteOutlined, PageviewOutlined } from '@mui/icons-material';
import { blue, amber } from '@mui/material/colors';

import { PROJECT_SENIORITY, PROJECT_STATUS } from 'constants/project';
import { POSITIONS } from '@nexxus/constants';
import { useNavigate } from 'react-router-dom';
import QuestionMarkOutlinedIcon from '@mui/icons-material/QuestionMarkOutlined';
import { useTheme, useMediaQuery } from '@mui/material';

const IntakeList = (props) => {
  const { setBreadcrumbs } = useBreadcrumbs();
  const navigate = useNavigate();
  const [pageSize, setPageSize] = useState(10);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { data: currentUser, isSuccess: currentUserIsSuccess } = useGetCurrentUserQuery();

  const [filterArchived] = useState({
    client: '',
    recruiters: [],
    position: '',
    technology: '',
    status: props?.isTasAdmin ? [PROJECT_STATUS.SUBMITTED] : [PROJECT_STATUS.DRAFT, PROJECT_STATUS.INTAKE],
    excludeStatus: '',
    page: '',
    limit: '',
  });
  const { data: projectsArchived, refetch: refetchProjectsArchived } = useGetProjectsQuery(
    { ...filterArchived, client: currentUser?.Client?.id },
    {
      skip: !currentUserIsSuccess,
    }
  );

  const NoDataOverlay = useCallback(() => {
    return (
      <Stack height="100%" alignItems="center" justifyContent="center">
        <Typography variant="h3" color="nexxusGrayLight.main" sx={{ fontWeight: 'bold' }}>
          No Data
        </Typography>
        <Typography variant="h6" color="nexxusGrayLight.main">
          0 Positions Archived
        </Typography>
      </Stack>
    );
  }, []);

  const columns = [
    {
      field: 'status',
      headerName: '',
      width: 50,
      sortable: false,
      renderCell: ({ row }) => {
        return (
          <FiberManualRecord
            sx={{
              mb: -0.7,
              color: row.status === PROJECT_STATUS.INTAKE ? blue[400] : amber[500],
              position: 'relative',
              top: '-5px',
            }}
          />
        );
      },
    },
    {
      field: 'position',
      headerName: 'POSITION',
      flex: 1,
      sortingOrder: ['desc', 'asc'],
      valueGetter: ({ row }) => {
        const position = Object.values(POSITIONS).find((ti) => ti._id === row.position).label || '';
        return capitalizeString(position);
      },
    },
    {
      field: 'seniority',
      headerName: 'SENIORITY',
      flex: 0.5,
      sortingOrder: ['desc', 'asc'],
      valueGetter: ({ row }) => {
        const seniority = PROJECT_SENIORITY.find((se) => se._id === row.seniority).shortLabel || '';
        return capitalizeString(seniority);
      },
    },
    {
      field: 'technology',
      headerName: 'TECHNOLOGY',
      headerAlign: 'center',
      align: 'center',
      flex: 1,
      sortingOrder: ['desc', 'asc'],
      valueGetter: ({ row }) => {
        const skill = row?.Skills.filter((sk) => sk.Project_Skill.skill_type === 3);
        return skill[0]?.name || '';
      },
    },
    {
      field: 'teamsize',
      headerName: 'HEADCOUNT',
      width: 130,
      headerAlign: 'center',
      sortingOrder: ['desc', 'asc'],
      renderCell: ({ row }) => {
        const color = row.hired_count > 0 ? 'success.main' : 'error.main';
        return (
          <Typography variant="cell" color={color} sx={{ margin: 'auto', marginRight: 'calc(auto + 30px)' }}>
            {row.hired_count ?? 0}/{row.teamsize}
          </Typography>
        );
      },
    },
    {
      field: 'skills',
      headerName: 'SKILLS',
      flex: 1,
      sortable: false,
      align: 'center',
      headerAlign: 'center',
      cellClassName: 'skill--cell',
      renderCell: (params) => {
        return <SkillsPopover label="VIEW ALL" skills={params.row.Skills} />;
      },
    },
    {
      field: 'dateCreated',
      headerName: 'DATE CREATED',
      align: 'center',
      headerAlign: 'center',
      width: 150,
      sortingOrder: ['desc', 'asc'],
      valueGetter: ({ row }) => row.createdAt,
      valueFormatter: (params) => {
        return moment(new Date(params.value)).format('MMM DD, YYYY ');
      },
    },
    {
      field: 'actions',
      type: 'actions',
      headerName: 'Actions',
      getActions: ({ row }) => {
        let actions = [];
        if (row.status === PROJECT_STATUS.DRAFT || row.status === PROJECT_STATUS.SUBMITTED)
          actions.push(
            <GridActionsCellItem
              icon={<EditNoteOutlined />}
              onClick={() => {
                navigate(`/${props?.isTasAdmin ? 'tas' : 'customer'}/projects/intake/${row.id}/edit`);
              }}
              label="Edit Intake Form"
              showInMenu
            />
          );
        if (row.status === PROJECT_STATUS.INTAKE)
          actions.push(
            <GridActionsCellItem
              icon={<PageviewOutlined />}
              onClick={() => {
                if (props?.isTasAdmin) {
                  navigate(`/tas/projects/intake/${row.id}/edit`);
                } else {
                  navigate(`/customer/projects/intake/${row.id}/view`);
                }
              }}
              label="View Intake Form"
              showInMenu
            />
          );
        return actions;
      },
    },
  ];

  useEffect(() => {
    setBreadcrumbs([
      {
        name: 'Dashboard',
      },
    ]);
  }, [setBreadcrumbs]);

  useEffect(() => {
    if (currentUser) refetchProjectsArchived();
  }, [currentUser, refetchProjectsArchived]);

  const renderContent = () => (
    <Stack spacing={0}>
      <Typography
        color="#0242D1"
        textTransform="capitalize"
        fontSize="24px"
        my="20px!important"
        sx={{ lineHeight: '28px', fontWeight: '500' }}
      >
        Saved Intake Forms{' '}
        <TooltipHelper
          textTip={'This section summarizes previous Intake Forms that you have provided for other positions.'}
          placement={isMobile ? 'top' : 'top-end'}
        >
          <QuestionMarkOutlinedIcon sx={{ color: '#fff', width: '0.5em', height: '0.5em', fontSize: '1.6rem' }} />
        </TooltipHelper>
      </Typography>
      <Stack
        direction="column"
        alignItems="center"
        justifyContent="space-between"
        sx={props?.isTasAdmin ? {} : { padding: '16px', background: 'white', borderRadius: '5px' }}
      >
        <Stack direction="row" justifyContent={'flex-start'} alignItems="center" spacing={2} sx={{ width: '100%' }}>
          <Typography sx={{ fontSize: 20, whiteSpace: 'nowrap' }}>Status</Typography>
          <Typography sx={{ color: 'lightgray', fontWeight: 'bold' }} alignItems="center">
            <FiberManualRecord sx={{ mb: -0.7, color: blue[400] }} />
            COMPLETED INTAKE
          </Typography>
          <Typography sx={{ color: 'lightgray', fontWeight: 'bold' }} alignItems="center">
            <FiberManualRecord sx={{ mb: -0.7, color: amber[500] }} />
            DRAFT
          </Typography>
        </Stack>
        <Box sx={{ width: '100%', mt: 4 }}>
          <DataGrid
            rows={projectsArchived || []}
            columns={columns}
            autoHeight
            rowsPerPageOptions={[5, 10, 20, 50, 100]}
            disableSelectionOnClick
            pageSize={pageSize}
            onPageSizeChange={(newPage) => setPageSize(newPage)}
            pagination
            disableColumnMenu
            components={{
              ...{
                NoRowsOverlay: () => NoDataOverlay(),
                NoResultsOverlay: () => NoDataOverlay(),
              },
            }}
            initialState={{
              sorting: {
                sortModel: [{ field: 'dateCreated', sort: 'desc' }],
              },
            }}
          />
        </Box>
      </Stack>
    </Stack>
  );

  if (props.isTasAdmin) {
    return (
      <MainLayoutV1 {...props} current="projects" avatarTopBar={currentUser?.Client?.logoUrl ?? ''}>
        {renderContent()}
      </MainLayoutV1>
    );
  }

  return (
    <MainLayout {...props} current="projects" avatarTopBar={currentUser?.Client?.logoUrl ?? ''}>
      {renderContent()}
    </MainLayout>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

const mapDispatchToProps = (dispatch) => {
  return {
    loginExpire: (user) => {
      dispatch(loginExpire(user));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(IntakeList);
