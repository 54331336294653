export const languages = [
  { _id: 'dutch', label: 'Dutch' },
  {
    _id: 'english',
    label: 'English',
  },
  { _id: 'french', label: 'French' },
  { _id: 'italian', label: 'Italian' },
  { _id: 'portuguese', label: 'Portuguese' },
  { _id: 'spanish', label: 'Spanish' },
];
