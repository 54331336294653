import { MailOutline as MailOutlineIcon, Visibility as VisibilityIcon } from '@mui/icons-material';
import { Button, Stack, styled, useMediaQuery, useTheme } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import CandidateCard from 'components/Candidates/CardidateCard/CandidateCard';
import { useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';

import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { subscribe, unsubscribe } from 'events.js';
import { Navigation, Pagination } from 'swiper';
import 'swiper/css';
import 'swiper/css/pagination';
import { Swiper, SwiperSlide } from 'swiper/react';
import { SkeletonCard } from '@nexxus/components';
import useCandidateFilter from 'hooks/useCandidateFilter';
import { useGetManagedCountriesQuery } from 'api/api';

const CARD_WIDTH = '300px';
const ARROW_SIZE = 1.7;
const HORIZONTAL_ARROWS_OFFSET = {
  desktop: '-56px',
  mobile: `calc(calc(100% - ${CARD_WIDTH}) / 2 - 32px)`,
  tablet: {
    vertical: '-27px',
    horizontal: '-36px',
  },
};

const btnlPositioning = (xAxis = { left: HORIZONTAL_ARROWS_OFFSET.desktop }) => ({
  position: 'absolute',
  top: `calc(50% -  30px )`,
  zIndex: '999',
  ...xAxis,
});

const ArrowIcon = styled(ArrowForwardIosIcon)((props) => ({
  fontSize: `${ARROW_SIZE}em`,
  [props.theme.breakpoints.down(700)]: {
    fontSize: `.8em`,
  },
}));

const FeaturedCandidates = (props) => {
  const [arrowOffset, setArrowOffset] = useState(null);
  const theme = useTheme();
  const isMobileViewPort = useMediaQuery(theme.breakpoints.down(700));
  const isTabletHorizontal = useMediaQuery(theme.breakpoints.between('md', 'lg'));
  const isTabletVertical = useMediaQuery(theme.breakpoints.between('sm', 'md'));
  const { featuredCandidates, handleFilterCandidates, isFetching, refetch } = useCandidateFilter(
    props.only_blacklisted,
    props.only_staff
  );
  const { data: locationData } = useGetManagedCountriesQuery();
  useEffect(() => {
    subscribe('CandidateSearchFilterUpdate', (event) => handleFilterCandidates(event?.detail));
    refetch();
    return () => {
      unsubscribe('CandidateSearchFilterUpdate');
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isMobileViewPort) {
      setArrowOffset(HORIZONTAL_ARROWS_OFFSET.mobile);
      return;
    }
    if (isTabletHorizontal) {
      setArrowOffset(HORIZONTAL_ARROWS_OFFSET.tablet.horizontal);
      return;
    }
    if (isTabletVertical) {
      setArrowOffset(HORIZONTAL_ARROWS_OFFSET.tablet.vertical);
      return;
    }
    setArrowOffset(HORIZONTAL_ARROWS_OFFSET.desktop);
  }, [isMobileViewPort, isTabletHorizontal, isTabletVertical]);

  return (
    <div style={{ position: 'relative', width: '100%' }}>
      <div style={{ ...btnlPositioning({ left: arrowOffset }) }} className="swiper-button-next-mui">
        <IconButton color="primary" aria-label="upload picture" component="label">
          <ArrowIcon sx={{ transform: 'rotate(180deg)' }} />
        </IconButton>
      </div>

      <div>
        <Swiper
          slidesPerView={1}
          spaceBetween={1}
          centerInsufficientSlides={true}
          autoHeight={true}
          breakpoints={{
            691: { slidesPerView: 2, slidesPerGroup: 2 },
            1446: { slidesPerView: 3, slidesPerGroup: 1 },
            1726: { slidesPerView: 4 },
          }}
          navigation={{
            prevEl: '.swiper-button-next-mui',
            nextEl: '.swiper-button-prev-mui',
          }}
          pagination={{
            clickable: true,
            type: 'custom',
          }}
          onSwiper={() => {}}
          modules={[Navigation, Pagination]}
          className="mySwiper"
        >
          {featuredCandidates.length === 0 &&
            isFetching &&
            [...new Array(4)].map((_e) => {
              return (
                <SwiperSlide key={`${uuidv4()}`}>
                  <Stack sx={{ height: '500px', justifyContent: 'center', alignItems: 'center' }}>
                    <SkeletonCard variant="candidate" />
                  </Stack>
                </SwiperSlide>
              );
            })}
          {featuredCandidates.length === 0 && !isFetching && (
            <Stack sx={{ height: '500px', justifyContent: 'center', alignItems: 'center' }}>
              <SkeletonCard variant="candidate" text="0 Candidates Found" />
            </Stack>
          )}
          {featuredCandidates.length > 0 &&
            featuredCandidates?.map((cand, j) => {
              return (
                <SwiperSlide key={j}>
                  <Stack sx={{ height: '500px' }}>
                    <CandidateCard key={cand.id} delay={j * 200} candidate={cand} locations={locationData} />
                    <Stack direction="row" spacing={1} justifyContent="center" sx={{ mt: 2 }}>
                      <Button
                        variant="contained"
                        color="primary"
                        startIcon={<VisibilityIcon />}
                        to={`/tas/candidates/${cand.id}`}
                      >
                        View Candidate
                      </Button>
                      {cand.email && (
                        <Button
                          variant="action-icon"
                          color="primary"
                          LinkComponent="a"
                          href={`mailto:${cand.email}?subject=Schedule%20Interview&body=${cand.first_name}%20${cand.last_name}%2C%0D%0A%0D%0AWe%20are%20reaching%20out%20to%20schedule%20an%20interview%20regarding%20the%20following%20position%3A%0D%0A%0D%0A%0D%0A%0D%0ABest%20regards%2C%0D%0A${props.auth.user.first_name}%20${props.auth.user.last_name}%2C%0D%0A${props.auth.user.email}%0D%0A%0D%0A`}
                        >
                          <MailOutlineIcon />
                        </Button>
                      )}
                    </Stack>
                  </Stack>
                </SwiperSlide>
              );
            })}
        </Swiper>
      </div>
      <div style={{ ...btnlPositioning({ right: arrowOffset }) }} className="swiper-button-prev-mui">
        <IconButton color="primary" aria-label="upload picture" component="label">
          <ArrowIcon />
        </IconButton>
      </div>
    </div>
  );
};

export default FeaturedCandidates;
