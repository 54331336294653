import IntakeForm from 'pages/customer/IntakeForm';
import { connect } from 'react-redux';

const TasIntakeForm = () => {
  return (
		<IntakeForm editing={true} key="create-intake" isTasAdmin />
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps)(TasIntakeForm);
