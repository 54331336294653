import { Stack, Typography, useTheme, useMediaQuery, Alert } from '@mui/material';
import { useGetCurrentUserQuery } from 'api/api';
import MainLayout from 'components/MainLayoutRedesign/MainLayout';
import MainLayoutV1 from 'components/MainLayout/MainLayout';
import { connect } from 'react-redux';
import { loginExpire } from 'store/auth';
import WrapperIntakeForm from './projectOverview/WrapperIntakeForm';
import { useNavigate, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import projectService from 'services/projectService';
import usePreventLocation from 'hooks/usePreventLocation';
import { TooltipHelper } from '@nexxus/components';
import QuestionMarkOutlinedIcon from '@mui/icons-material/QuestionMarkOutlined';

const IntakeForm = (props) => {
  const { ProjectId } = useParams();
  const hideWidgetMatchCandidates = true;
  const { setHasChanged, hasChanged, LEAVE_RESTRICTION_MESSAGE } = usePreventLocation();
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { data: currentUser } = useGetCurrentUserQuery();

  const [projectDB, setProjectDB] = useState({});

  const setSkills = (bodySkills) => {
    const skills = bodySkills.map((sk) => {
      return {
        SkillId: sk.SkillId,
        yearExperience: sk.year_experience,
        type: [3, 1].includes(sk.skill_type) ? 'must' : 'nice',
      };
    });
    for (let i = bodySkills.length; i < 5; i++) {
      skills.push({
        SkillId: '',
        yearExperience: '',
        type: '',
      });
    }
    return skills;
  };

  useEffect(() => {
    (async () => {
      if (ProjectId && currentUser) {
        try {
          const { data } = await projectService.getProject(ProjectId);
          if (!data) return navigate('/404');

          const body = {
            ClientId: currentUser?.Client?.id || data?.ClientId,
            ProjectId: ProjectId,
            intake_position_title: data.position,
            intake_seniority: data.seniority,
            intake_headcount: data.teamsize,
            intake_maxrate_usd: data.maxrate,
            intake_description: data.description,
            intake_timezone: data.timezone,
            intake_holidays: data.holidays,
            intake_working_hours_open: data.working_hours.monday.start,
            intake_working_hours_close: data.working_hours.monday.end,
            intake_interview_process: data.interview_process,
            intake_job_description: data.job_description_link,
            intake_codetest_link: data.codetest_link,
            intake_hardware_needs: data?.hardware_needs ?? '',
            intake_status: data?.status === 21 ? 'SUBMITTED' : 'DRAFT',
            intake_skills: setSkills(data?.Project_Skills),
            intake_codetest_file: data.customer_job_link,
            intake_uploads: data.meta_uploads?.customer_job_link,
            industry_experienceCV:
              data?.ProjectIndustryExperiences.map((i) => ({ option: i.IndustryExperienceId, years: Number(i.years) })) ||
              [],
          };

          setProjectDB(body);
        } catch (e) {
          if (e.response.data.code === 422) return navigate('/404');
        }
      }
    })();
  }, [currentUser, ProjectId, navigate]);

  const renderContent = () => (
    <>
      <Stack spacing={2}>
        <Typography
          color="#0242D1"
          textTransform="capitalize"
          fontSize="24px"
          mt="20px!important"
          mb="4px!Important"
          sx={{ lineHeight: '28px', fontWeight: '500' }}
        >
          Customer Intake Form{' '}
          <TooltipHelper
            textTip={
              'This section provides us with the essential information for each Position that the Sonatafy team works on.'
            }
            placement={isMobile ? 'top' : 'top-end'}
          >
            <QuestionMarkOutlinedIcon sx={{ color: '#fff', width: '0.5em', height: '0.5em', fontSize: '1.6rem' }} />
          </TooltipHelper>
        </Typography>
        {projectDB?.intake_status === 'SUBMITTED' && !props.isTasAdmin ? (
          <Alert severity="info">
            Please review the intake form carefully and update it with any necessary changes before approving and submitting.
          </Alert>
        ) : null}
      </Stack>
      <WrapperIntakeForm
        hasChanged={hasChanged}
        setHasChanged={setHasChanged}
        leaveMessage={LEAVE_RESTRICTION_MESSAGE}
        client={currentUser}
        project={projectDB}
        editing={props.editing}
        hideWidget={hideWidgetMatchCandidates}
        isTasAdmin={props.isTasAdmin}
      ></WrapperIntakeForm>
    </>
  );

  if (props?.isTasAdmin) {
    return <MainLayoutV1 current="projects">{renderContent()}</MainLayoutV1>;
  }

  return (
    <MainLayout current="projects" avatarTopBar={currentUser?.Client?.logoUrl ?? ''}>
      {renderContent()}
    </MainLayout>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

const mapDispatchToProps = (dispatch) => {
  return {
    loginExpire: (user) => {
      dispatch(loginExpire(user));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(IntakeForm);
