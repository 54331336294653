const ADMIN_SIDEBAR = [
  {
    id: 1,
    title: 'Dashboard',
    url: '/admin/dashboard',
    iconName: 'settings-icon',
    expandedName: 'dashboard',
  },
  // {
  //   id: 2,
  //   title: 'Import',
  //   url: '#',
  //   iconName: 'projects-icon',
  //   submenuItems: [
  //     {
  //       title: 'Import Candidates',
  //       url: '/import/candidates',
  //     },
  //   ],
  //   expandedName: 'import',
  // },
  {
    id: 3,
    title: 'User Access Control',
    url: '#',
    iconName: 'user-config',
    submenuItems: [
      {
        title: 'View Users',
        url: '/tas/users',
      },
    ],
    expandedName: 'users',
  },
  {
    id: 7,
    title: 'Audit Trails',
    url: '#',
    iconName: 'reports-icon',
    submenuItems: [
      {
        title: 'View Logs',
        url: '/audittrails',
      },
    ],
    expandedName: 'audittrails',
  },
];

const TAS_SIDEBAR = [
  { id: 1, title: 'Dashboard', url: '/tas/dashboard', iconName: 'settings-icon' },
  {
    id: 2,
    title: 'Candidates',
    url: '#',
    iconName: 'candidates-icon',
    submenuItems: [
      {
        title: 'Create Candidate',
        url: '/tas/candidates/new',
        iconName: 'profile-icon',
      },
      {
        title: 'Search Candidate',
        url: '/tas/candidates/search',
        iconName: 'profile-icon',
      },
      {
        title: 'Blacklisted Candidates',
        url: '/tas/candidates/blacklisted',
        iconName: 'profile-icon',
      },
      {
        title: 'Staff Engineers',
        url: '/tas/candidates/staff',
        iconName: 'profile-icon',
      },
    ],
    expandedName: 'candidates',
  },
  {
    id: 3,
    title: 'Customers',
    url: '#',
    iconName: 'clients-icon',
    submenuItems: [
      {
        title: 'View Customers',
        url: '/tas/customers',
      },
    ],
    expandedName: 'customers',
  },
  {
    id: 4,
    title: 'Projects',
    url: '#',
    iconName: 'projects-icon',
    submenuItems: [
      {
        title: 'View Projects',
        url: '/tas/projects',
      },
    ],
    expandedName: 'projects',
  },
  {
    id: 5,
    title: 'Reports',
    url: '#',
    iconName: 'projects-icon',
    submenuItems: [
      {
        title: 'Manage Reports',
        url: '/tas/reports',
      },
      {
        title: 'Find Reports',
        url: '/tas/reports/search',
      },
    ],
    expandedName: 'reports',
  },
];

const SUPERTAS_SIDEBAR = [
  { id: 1, title: 'Dashboard', url: '/tas/dashboard', iconName: 'settings-icon' },
  {
    id: 2,
    title: 'Candidates',
    url: '#',
    iconName: 'candidates-icon',
    submenuItems: [
      {
        title: 'Create Candidate',
        url: '/tas/candidates/new',
        iconName: 'profile-icon',
      },
      {
        title: 'Search Candidate',
        url: '/tas/candidates/search',
        iconName: 'profile-icon',
      },
      {
        title: 'Blacklisted Candidates',
        url: '/tas/candidates/blacklisted',
        iconName: 'profile-icon',
      },
      {
        title: 'Staff Engineers',
        url: '/tas/candidates/staff',
        iconName: 'profile-icon',
      },
    ],
    expandedName: 'candidates',
  },
  {
    id: 3,
    title: 'Customers',
    url: '#',
    iconName: 'clients-icon',
    submenuItems: [
      {
        title: 'Manage Customers',
        url: '/tas/customers',
      },
      {
        title: 'Create A Customer',
        url: '/tas/customer/create',
      },
    ],
    expandedName: 'customers',
  },
  {
    id: 4,
    title: 'Projects',
    url: '#',
    iconName: 'projects-icon',
    submenuItems: [
      {
        title: 'Manage Projects',
        url: '/tas/projects',
      },
      {
        title: 'Create Project',
        url: '/tas/projects/create',
      },
      {
        title: 'Pre-fill Intake Form',
        url: '/tas/projects/intake-form',
      },
      {
        title: 'Saved Intake Forms',
        url: '/tas/projects/saved-intake-form',
      }
    ],
    expandedName: 'projects',
  },
  {
    id: 5,
    title: 'Reports',
    url: '#',
    iconName: 'summarize-2',
    submenuItems: [
      {
        title: 'Manage Reports',
        url: '/tas/reports',
      },
      {
        title: 'Find Reports',
        url: '/tas/reports/search',
      },
    ],
    expandedName: 'reports',
  },
  // Commented out as per NEX-865
  // {
  //   id: 6,
  //   title: 'Import',
  //   iconName: 'import-contacts',
  //   url: '#',
  //   submenuItems: [
  //     {
  //       title: 'Import Candidates',
  //       url: '/import/candidates',
  //     },
  //     {
  //       title: 'Duplicate Candidates',
  //       url: '/import/candidates/duplicates',
  //     },
  //     {
  //       title: 'Invalid Candidates',
  //       url: '/import/candidates/invalid',
  //     },
  //   ],
  //   expandedName: 'import',
  // },
  {
    id: 7,
    title: 'User Access Control',
    url: '#',
    iconName: 'user-config',
    submenuItems: [
      {
        title: 'View Users',
        url: '/tas/users',
      },
    ],
    expandedName: 'users',
  },
  {
    id: 8,
    title: 'Administration Module',
    url: '#',
    iconName: 'user-config',
    submenuItems: [
      {
        title: 'Manage Tags',
        url: '/tas/admin/tags',
      },
      {
        title: 'Manage Skills',
        url: '/tas/admin/skills',
      },
      {
        title: 'Manage Countries',
        url: '/tas/admin/countries',
      },
    ],
    expandedName: 'administration',
  },
];

const ACCOUNTMANAGER_SIDEBAR = [
  { id: 1, title: 'Dashboard', url: '/accountmanager/dashboard', iconName: 'settings-icon' },
  {
    id: 2,
    title: 'Customers',
    url: '#',
    iconName: 'clients-icon',
    expandedName: 'customers',
  },
  {
    id: 3,
    title: 'Projects',
    iconName: 'projects-icon',
    submenuItems: [
      {
        title: 'Manage Projects',
        url: '/accountmanager/projects',
      },
      {
        title: 'Candidate Overview',
        url: '/accountmanager/projects/overview',
      },
    ],
    expandedName: 'projects',
  },
];

const CUSTOMER_SIDEBAR = [
  { id: 1, title: 'Dashboard', url: '/customer/dashboard', iconName: 'settings-icon' },
  {
    id: 2,
    title: 'Positions',
    url: '#',
    iconName: 'projects-icon',
    submenuItems: [
      {
        title: 'Candidate Overview',
        url: '/customer/projects/overview',
      },
    ],
    expandedName: 'projects',
  },
];

const SUPERCUSTOMER_SIDEBAR = [
  { id: 1, title: 'Dashboard', url: '/customer/dashboard', iconName: 'settings-icon' },
  {
    id: 2,
    title: 'Positions',
    url: '#',
    iconName: 'projects-icon',
    submenuItems: [
      {
        title: 'Candidate Overview',
        url: '/customer/projects/overview',
      },
      {
        title: 'Create a Role',
        url: '/customer/projects/intake',
      },
    ],
    expandedName: 'projects',
  },
  {
    id: 3,
    title: 'Reports',
    url: '/customer/reports',
    iconName: 'reports-icon',
    expandedName: 'reports',
  },
];

const CANDIDATE_SIDEBAR = [{ id: 1, title: 'Dashboard', url: '/customer/dashboard', iconName: 'settings-icon' }];

export {
  ADMIN_SIDEBAR,
  TAS_SIDEBAR,
  SUPERTAS_SIDEBAR,
  CUSTOMER_SIDEBAR,
  ACCOUNTMANAGER_SIDEBAR,
  CANDIDATE_SIDEBAR,
  SUPERCUSTOMER_SIDEBAR,
};
