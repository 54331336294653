import MainLayout from 'components/MainLayoutRedesign/MainLayout';
import { connect } from 'react-redux';
import { loginExpire } from 'store/auth';
import { Typography, Box, Paper, List, ListItem, styled } from '@mui/material';
import { useGetCurrentUserQuery } from 'api/api';
import useBreadcrumbs from 'hooks/useBreadcrumbs';
import { useEffect } from 'react';
import { Link } from 'react-router-dom';

const CustomTitle = styled(Typography)(() => ({
  fontSize: '24px',
  fontWeight: '500',
  margin: '1em 0px',
}));

const CustomSubTitle = styled(Typography)(() => ({
  mt: 2,
  fontSize: '16px',
  lineHeight: '19px',
  fontWeight: '400',
  marginBottom: 5,
}));

const CustomLink = styled(Link)(({ theme }) => ({
	fontWeight: '400',
	color: theme.palette.primary.main,
	textDecoration: 'none',
  }));
  

const SupportAndServiceLevels = (props) => {
  const { setBreadcrumbs } = useBreadcrumbs();
  const { data: currentUser } = useGetCurrentUserQuery();

  useEffect(() => {
    setBreadcrumbs([
      {
        name: 'Dashboard',
      },
    ]);
  }, [setBreadcrumbs]);

  return (
    <MainLayout {...props} current="settings" avatarTopBar={currentUser?.Client?.logoUrl ?? ''}>
      <Box>
        <Typography my="20px" variant="h1" color="primary">
          Support and Service Levels (SLA)
        </Typography>
      </Box>
      <Paper elevation={0} sx={{ my: 4, p: 4 }}>
        <CustomTitle>Introduction</CustomTitle>
        <CustomSubTitle>
          This Service Level Agreement (SLA) outlines the levels of service expected from the Nexxus SaaS Platform. It
          ensures that the platform&apos;s users receive timely and effective support for their needs, including feedback,
          technical support, and other related services.
        </CustomSubTitle>
        <CustomTitle>Scope</CustomTitle>
        <CustomSubTitle>
          This SLA applies to all Customers and Talent Acquisition Agents using the Nexxus SaaS Platform and covers the
          following services:
        </CustomSubTitle>
        <List>
          <ListItem>&#9679; Feedback and suggestions</ListItem>
          <ListItem>&#9679; Technical support and troubleshooting</ListItem>
          <ListItem>&#9679; Account inquiries</ListItem>
          <ListItem>&#9679; Feature requests and enhancements</ListItem>
        </List>
        <CustomTitle>Service Availability</CustomTitle>
        <CustomSubTitle>
          Nexxus will be available 24/7, with the exception of scheduled maintenance, which will be communicated at least 24
          hours in advance.
        </CustomSubTitle>
        <CustomTitle>Support Hours</CustomTitle>
        <List>
          <ListItem>
            &#9679; <b>&nbsp;Standard Support Hours:&nbsp;</b> Monday to Friday, 8 AM to 5 PM PST
          </ListItem>
          <ListItem>
            &#9679; <b>&nbsp;Emergency Support:&nbsp;</b> Monday to Friday, 8 AM to 5 PM PST
          </ListItem>
        </List>
        <CustomTitle>Response Times</CustomTitle>
        <CustomSubTitle>The following response times apply based on the priority of the issue:</CustomSubTitle>
        <List style={{marginLeft: '1em'}}>
          <p>
            1. <b>Critical (P1)</b>: Platform is down, or major functionality is severely impacted.
            <ListItem>
              &#9679; <b>&nbsp;Response Time:&nbsp;</b> 60 minutes
            </ListItem>
			<ListItem>
              &#9679; <b>&nbsp;Resolution Time:&nbsp;</b> 4 hours
            </ListItem>
          </p>
		  <p>
            2. <b>High (P2)</b>: Significant impact on business operations, but the platform is still functional.
            <ListItem>
              &#9679; <b>&nbsp;Response Time:&nbsp;</b> 4 hours
            </ListItem>
			<ListItem>
              &#9679; <b>&nbsp;Resolution Time:&nbsp;</b> 1 business day
            </ListItem>
          </p>
		  <p>
            3. <b>Medium (P3)</b>: Moderate impact with partial loss of functionality or performance issues.
            <ListItem>
              &#9679; <b>&nbsp;Response Time:&nbsp;</b> 1 business day
            </ListItem>
			<ListItem>
              &#9679; <b>&nbsp;Resolution Time:&nbsp;</b> 3 business day
            </ListItem>
          </p>
		  <p>
            4. <b>Low (P4)</b>: Minor issues, general questions, feature requests, or suggestions.
            <ListItem>
              &#9679; <b>&nbsp;Response Time:&nbsp;</b> 5 business day
            </ListItem>
			<ListItem>
              &#9679; <b>&nbsp;Resolution Time:&nbsp;</b> 1 month
            </ListItem>
          </p>
        </List>
        <CustomTitle>Support Channels</CustomTitle>
        <CustomSubTitle>Customers can reach the support team through the following channels:</CustomSubTitle>
        <List>
          <ListItem>
            &#9679; <b>&nbsp;Email:&nbsp;</b>
			<CustomLink to="mailto:notifications@sonatafy.com?subject=Contact Support&body=">
				notifications@sonatafy.com
			</CustomLink>
          </ListItem>
          <ListItem>
            &#9679; <b>&nbsp;Contact Form:&nbsp;</b> Available on the platform’s help widget
          </ListItem>
          <ListItem>
            &#9679; <b>&nbsp;Support Portal:&nbsp;</b>
			<CustomLink target="_blank" to="https://app.sonatafynexxus.mx/customer/contact-form">
				https://app.sonatafynexxus.mx/customer/contact-form
			</CustomLink>
          </ListItem>
        </List>
        <CustomTitle>Feedback and Suggestions</CustomTitle>
        <List>
          <ListItem>
            &#9679; <b>&nbsp;Feedback Submission:&nbsp;</b> Customers can submit feedback and suggestions through the support portal or
            by email.
          </ListItem>
          <ListItem>
            &#9679; <b>&nbsp;Acknowledgment:&nbsp;</b> All feedback will be acknowledged within 1 business day.
          </ListItem>
          <ListItem>
            &#9679; <b>&nbsp;Review and Response:&nbsp;</b> Feedback will be reviewed, and a response will be provided within five
            business days, outlining any potential actions or timelines.
          </ListItem>
        </List>
        <CustomTitle>Account and Inquiries</CustomTitle>
        <List>
          <ListItem>
            &#9679; <b>&nbsp;Inquiry Submission:&nbsp;</b> Account inquiries can be submitted via email or the support portal.
          </ListItem>
          <ListItem>
            &#9679; <b>&nbsp;Response Time:&nbsp;</b> All inquiries will be responded to within 1 business day.
          </ListItem>
          <ListItem>
            &#9679; <b>&nbsp;Resolution Time:&nbsp;</b> Resolution time will vary depending on the complexity but will be aimed to be
            resolved within 3 business days.
          </ListItem>
        </List>
        <CustomTitle>Feature Requests</CustomTitle>
        <List>
          <ListItem>
            &#9679; <b>&nbsp;Submission:&nbsp;</b> Customers can submit feature requests through the support portal or email.
          </ListItem>
          <ListItem>
            &#9679; <b>&nbsp;Acknowledgment:&nbsp;</b> All feature requests will be acknowledged within 1 business day.
          </ListItem>
          <ListItem>
            &#9679; <b>&nbsp;Review Process:&nbsp;</b> Feature requests will be reviewed monthly, and customers will be informed of the
            decision and any potential implementation timelines within 10 business days.
          </ListItem>
        </List>
        <CustomTitle>Escalation Process</CustomTitle>
        <CustomSubTitle>
          If a customer is not satisfied with the response or resolution provided, they can escalate the issue through the
          following steps:
        </CustomSubTitle>
        <List>
          <ListItem>
            &#9679; <b>&nbsp;Level 1:&nbsp;</b> Email the Talent Acquisition lead&apos;s support team.
          </ListItem>
          <ListItem>
            &#9679; <b>&nbsp;Level 2:&nbsp;</b> If unresolved, escalate to the Engineering Manager.
          </ListItem>
          <ListItem>
            &#9679; <b>&nbsp;Level 3:&nbsp;</b> Finally, escalate to the PMO Manager.
          </ListItem>
        </List>
        <CustomTitle>Monitoring and Reporting</CustomTitle>
        <List>
          <ListItem>
            &#9679; <b>&nbsp;Weekly Reports:&nbsp;</b> Customers will receive weekly reports detailing their candidate&apos;s pipeline
            status of their corresponding active positions.
          </ListItem>
        </List>
        <CustomTitle>Continuous Improvement</CustomTitle>
        <CustomSubTitle>
          Regular feedback will be solicited from customers to improve the SLA and service delivery. Based on customer
          feedback and service performance, the SLA will be reviewed and updated annually or as needed.
        </CustomSubTitle>
        <CustomTitle>Conclusion</CustomTitle>
        <CustomSubTitle>
          This SLA ensures high service and support for all Nexxus SaaS Platform customers. By adhering to these guidelines,
          we aim to provide reliable and responsive service that meets our customer&apos;s needs.
        </CustomSubTitle>
        <CustomTitle>Contact Information</CustomTitle>
        <List>
          <ListItem>
            &#9679; <b>&nbsp;Email:&nbsp;</b>
			<CustomLink to="mailto:notifications@sonatafy.com?subject=Contact Support&body=">
				notifications@sonatafy.com
			</CustomLink>
          </ListItem>
          <ListItem>
            &#9679; <b>&nbsp;Support Portal:&nbsp;</b>
			<CustomLink target="_blank" to="https://app.sonatafynexxus.mx/customer/contact-form">
				https://app.sonatafynexxus.mx/customer/contact-form
			</CustomLink>
          </ListItem>
        </List>
      </Paper>
    </MainLayout>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

const mapDispatchToProps = (dispatch) => {
  return {
    loginExpire: (user) => {
      dispatch(loginExpire(user));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SupportAndServiceLevels);
