import ReactQuill from "react-quill";

const modules = {
	toolbar: [
		[{ header: [1, 2, false] }],
		['bold', 'italic', 'underline', 'strike', 'blockquote'],
		[{ list: 'ordered' }, { list: 'bullet' }, { indent: '-1' }, { indent: '+1' }],
		['link'],
		['clean'],
	],
};

const formats = [
	'header',
	'bold',
	'italic',
	'underline',
	'strike',
	'blockquote',
	'list',
	'bullet',
	'indent',
	'link',
	'image',
];

const StyledQuillInput = ({
	onChange,
	currentValue,
	style = {},
	isDisabled = false
}) => {
	return (
		<ReactQuill
			theme="snow"
			value={currentValue}
			readOnly={isDisabled}
			onChange={(value) => onChange(value)}
			modules={modules}
			formats={formats}
			style={{ width: '100%', opacity: isDisabled ? '0.5' : '1', ...style }}
		/>
	)
}

export default StyledQuillInput;